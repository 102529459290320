<template>

<app-wrapper class="band">

	<app-wrapper :content="true" class="band-content">

		<div class="band-content-item" v-if="$state.page.joined">
			{{ $state.page.joined }} <small>Year joined Luqmani Thompson</small>
		</div>

		<div class="band-content-item" v-if="$state.page.qualified">
			{{ $state.page.qualified }} <small>Year of Qualification</small>
		</div>

		<a :href="$state.page.logo_1_url" target="_blank" class="band-content-item" v-if="$state.page.logo_1 && $state.page.logo_1_url" v-bgimage="$state.page.logo_1" />
		<div class="band-content-item" v-if="$state.page.logo_1 && !$state.page.logo_1_url" v-bgimage="$state.page.logo_1" />

		<a :href="$state.page.logo_2_url" target="_blank" class="band-content-item" v-if="$state.page.logo_2 && $state.page.logo_2_url" v-bgimage="$state.page.logo_2" />
		<div class="band-content-item" v-if="$state.page.logo_2 && !$state.page.logo_2_url" v-bgimage="$state.page.logo_2" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.band {
	background-color: #f4f4f4;
	height: 162px;
	padding: 21px 0px;
}

.is-tablet .band {
	height: auto;
}

.is-mobile .band {
	height: auto;
}

.band-content {
	display: flex;
	justify-content: center;
}

.is-tablet .band-content {
	flex-wrap: wrap;
}

.is-mobile .band-content {
	flex-wrap: wrap;
}

.band-content-item {
	width: 25%;
	height: 120px;
	border-right: 4px solid #e9e9e9;
}

.is-tablet .band-content-item {
	width: 50%;
	height: 100px;
	border-right: 0px;
}

.is-mobile .band-content-item {
	width: 100%;
	height: 80px;
	border-right: 0px;
}

.band-content-item:last-child {
	border-right: 0px;
}

.band-content-item {
	color: #1A2046;
	font-size: 26px;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.is-device .band-content-item {
	font-size: 18px;
}

.band-content-item small {
	color: #45464B;
	display: block;
	font-size: 16px;
	font-weight: 500;
	margin-top: 18px;
}

.is-device .band-content-item small {
	font-size: 14px;
}

</style>
